import Worker from 'worker-loader!@/filterWorker';
import saveAs from 'file-saver'
import jsonpack from "jsonpack";
import {decompress} from "compress-json";

export default {
    data() {
        let ballCount = [];
        [0,1,2,3].forEach(b => {
            [0,1,2].forEach(s => {
                ballCount.push({
                    _id:b+'-'+s,
                    name:b+'-'+s
                })
            })
        })
        return {
            video: null,
            form: {},
            pitchers: [],
            batters: [],
            catchers: [],
            teams: [],
            gamedata:[],
            game: {},
            results:[],
            plans:{},
            filterWorker: null,
            marks: [],
            pitch_type: [],
            feature: [
                {_id: 1, name: 'ゴロ'},
                {_id: 2, name: 'フライ'},
                {_id: 3, name: 'ライナー'},
            ],
            steal: [
                {_id: 2, name: '2塁'},
                {_id: 3, name: '3塁'},
                {_id: 4, name: '本塁'},
            ],
            ballCount,
            battingOrder:[1,2,3,4,5,6,7,8,9].map(x=>({_id:x,name:x})),
            runnerSituationData: [
                { _id: 1, name: 'なし' },
                { _id: 2, name: 'あり' },
                { _id: 3, name: '得点圏' },
                { _id: 4, name: '1塁' },
                { _id: 5, name: '2塁' },
                { _id: 6, name: '3塁' },
                { _id: 7, name: '12塁' },
                { _id: 8, name: '13塁' },
                { _id: 9, name: '23塁' },
                { _id: 10, name: '満塁' }
            ],
            loading: false
        }
    },
    methods: {
        exportCSV() {
            if (this.marks.length) {
                const csv = this.marks.map(x=>{
                    return x.time+','+x.endtime+','+'"'+x.tip+'"'
                }).join("\n")
                const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
                saveAs(blob, this.teams[this.game.first_team_id].name+'-'+this.teams[this.game.second_team_id].name+".csv");
            }
        },
        submit(e) {
            e.preventDefault();
            if (this.marks.length) {
                this.loading = true;
                this.$getComponent('App').loading(true)
                let filters = Object.keys(this.form).filter(key=>this.form[key] && this.form[key].length).map(key => {
                    switch(key) {
                        case 'pitcher':
                            return '投手: '+this.pitchers.find(x=>x._id===this.form[key]).name
                        case 'batter':
                            return '打者: '+this.batters.find(x=>x._id===this.form[key]).name
                        case 'catcher':
                            return '捕手: '+this.catchers.find(x=>x._id===this.form[key]).name
                        case 'feature':
                            return '打球性質: '+this.form[key].map(y=>this.feature.find(x=>x._id===y).name).join(', ')
                        case 'balltype':
                            return '球種: '+this.form[key].map(y=>this.pitch_type.find(x=>x._id===y).name).join(', ')
                        case 'order':
                            return '打順: '+this.form[key].join(', ')
                        case 'bsCount':
                            return 'カウント別: '+this.form[key].join(', ')
                        case 'result':
                            return '結果: '+this.form[key].map(y=>this.results.find(x=>x._id===y).name).join(', ')
                    }
                })
                this.$http.get('url/play/' +this. video.video_id).then((r) => {
                    if (r && r.data && r.data.url) {
                        this.$refs.videoSearchModal.hide();
                        this.$root.$emit('openVideo', r.data.url, this.video, {
                            marks: this.marks
                        },filters);
                    }
                }).catch(function (error) {
                    if (error.response) {
                        if (error.response.data && error.response.data.error) {
                            this.$snotify.error(error.response.data.error);
                        } else {
                            this.$snotify.error('サーバーエラー');
                        }
                    }
                }).finally(() => {
                    this.loading = false;
                    this.$root.$emit('endSpinner');
                })
            }
        },
        workerMethod(event) {
            this.marks = event.data.marks
            console.log(event.data)
        },
        modalOpen(video) {
            this.$getComponent('App').loading(true)
            this.$http.get('video/' + video.video_id + '?data=true').then((r) => {
                this.$root.$emit('endSpinner');
                if (r  && r.data && r.data.video && r.data.video.data) {
                    this.marks = []
                    this.gamedata = []
                    this.pitchers = []
                    this.batters = []
                    this.catchers = []
                    this.teams = []
                    this.results = []
                    this.game = {}
                    this.plan = {}
                    this.pitch_type = []
                    let data = r.data.video.data
                    if (typeof data === 'string') {
                        if (data.startsWith('[') && data.endsWith(']')) {
                            data = decompress(JSON.parse(data))
                        } else {
                            data = jsonpack.unpack(data)
                        }
                    }
                    if (data && data.gamedata) {
                        let pitchers = new Set()
                        let batters = new Set()
                        let catchers = new Set()

                        this.gamedata = data.gamedata.filter(x => !!x.start_time && !!x.end_time).map(x=>{
                            pitchers.add(x.pitcher.id)
                            batters.add(x.batter.id)
                            catchers.add(x.catcher.id)
                            return Object.freeze(x)
                        })
                        this.teams = Object.freeze(data.teams)
                        this.results = Object.values(data.result).map(x => ({_id:Math.trunc(x._id.replace('result-','')),name:x.name}))
                        this.plan = Object.freeze(data.plan)
                        this.memo = Object.freeze(data.memo)
                        this.game = Object.freeze(data.game)
                        this.pitch_type = Object.values(data.pitch_type)
                        let bp = [{_id:'all',name:'すべて'}, {
                            _id: 'left',
                            name: '左'},
                            {
                                _id: 'right',
                                name: '右'}]
                        this.pitchers = bp.concat(...(Array.from(pitchers).map(x=>Object.freeze(data.players[x]))))
                        this.batters = bp.concat(...(Array.from(batters).map(x=>Object.freeze(data.players[x]))))
                        this.catchers = [{_id:'all',name:'すべて'}].concat(...(Array.from(catchers).map(x=>Object.freeze(data.players[x]))))
                    }
                    this.video = video;
                    this.$refs.videoSearchModal.show();
                    this.filterWorker.postMessage({
                        oper: 'init',
                        gamedata: this.gamedata,
                        plans: this.plan,
                        players: [...this.pitchers,...this.batters,...this.catchers].reduce((a,c)=>{
                            a[c._id] = c
                            return a
                        },{})
                    })
                    this.$nextTick(()=> {
                        this.search(this.form)
                    })
                }
            }).catch((error) => {
                this.$root.$emit('endSpinner');
                if (error.response && error.response.data && error.response.data.error) {
                    this.$snotify.error(error.response.data.error);
                } else {
                    console.error(error)
                    this.$snotify.error('サーバーエラー');
                }
            })
        },
        search(val) {
            let filter = this.$copy(val)
            let lr = {
                left: '1',
                right: '2'
            }
            if (filter.pitcher === 'left' || filter.pitcher === 'right') {
                filter.t_lr = lr[filter.pitcher]
                filter.pitcher = ""
            }
            if (filter.batter === 'left' || filter.batter === 'right') {
                filter.b_lr = lr[filter.batter]
                filter.batter = ""
            }

            // すべてを選んだ時
            if (filter.pitcher === 'all'){
                filter.pitcher = ""
            }
            if (filter.catcher === 'all'){
                filter.catcher = ""
            }
            if (filter.batter === 'all'){
                filter.batter = ""
            }
            console.log(filter)
            this.filterWorker.postMessage({
                oper: 'filter',
                filter: filter
            })
        }
    },
    watch: {
        form: {
            handler(val) {
                this.search(val)
            },
            deep: true
        }
    },
    created() {
        this.filterWorker = new Worker();
        this.filterWorker.addEventListener('message', this.workerMethod)
        this.$root.$on('openVideoSearchModal', this.modalOpen);
    },
    destroyed() {
        this.filterWorker.removeEventListener('message', this.workerMethod)
        this.filterWorker.terminate()
        this.$root.$off('openVideoSearchModal', this.modalOpen);
    }
}
