<template>
    <main role="main" class="container-fluid">
        <b-alert variant="danger" v-if="news.system && news.system.length > 0" show dismissible
                 @dismissed="newsDismissed('system',news.system[0].aid)"><strong>{{ news.system[0].title }}</strong>
            <br>{{ news.system[0].body }}
        </b-alert>
        <b-tabs content-class="mt-3" @click="tabClick">
            <b-tab title="映像" active>
                <Videos :teams2="teams2" :teams="teams" @play-video="getPlayURL"></Videos>
            </b-tab>
            <b-tab title="ユーザー">
                <Users :teams="teams" :teams2="teams2"></Users>
            </b-tab>
            <!--b-tab title="復旧リクエスト"><RestoreRequest></RestoreRequest></b-tab-->
        </b-tabs>
    </main>
</template>

<script>
import Videos from '@/components/Staff/Videos'
import Users from '@/components/Staff/Users'
// import RestoreRequest from '@/components/Staff/RestoreRequest'

export default {
    name: 'Staff',
    components: {Users, Videos},
    props: {
        groups: Array
    },
    data() {
        return {
            news: {},
            statusVariant: this.$parent.statusVariant,
            status: this.$parent.status
        }
    },
    methods: {
        newsDismissed(type, aid) {
            switch (type) {
                case 'system':
                    this.$ls.set('news-s', aid)
                    break;
            }
        },
        tabClick(e) {
            console.log(e)
        },
        getPlayURL(item) {
            console.log(item)
            this.$parent.getPlayURL(item, url => {
                this.$root.$emit('openVideo', url, item);
            })
        }
    },
    computed: {
        teams() {
            return this.groups.map(item => {
                return {text: item.name, value: item.group_id}
            });
        },
        teams2() {
            let l = this.groups.map(item => {
                return {text: item.name, value: item.group_id}
            });
            l.unshift({
                text: 'すべて', value: null
            })
            return l;
        }
    },
    mounted() {
        this.$root.$on('updateNews', (news) => {
            this.news = news
        });
        this.$root.$on('updateTable', () => {
            if (this.$refs.table) {
                this.$refs.table.refresh()
            }
        });
        this.$root.$on('endSpinner', () => {
            if (this.$refs.spinner) {
                this.$refs.spinner.style.display = 'none';
            }
        });
    }
}
</script>

<style scoped>

</style>

<style>
th.status {
    width: 120px;
}

th.group {
    width: 150px;
}

th.date {
    width: 150px;
}
</style>
