<template>
  <b-modal id="modalUploadProgress" title="アップロード状況" size="lg" ref="uploadModalProgress" @shown="modalOpen">
    <div style="max-height: 600px; overflow-y: auto">
      <b-list-group>
        <b-list-group-item v-for="upload in uploads" :key="upload.id">
          {{upload.name}}<br>
            <b-progress>
              <b-progress-bar :value="upload.progress" :label="upload.progress + '%'" show-progress :animated="upload.uploading" :variant="upload.error ? 'danger': (upload.uploaded ? 'success':'primary')" />
            </b-progress>
          <small class="text-muted" v-if="upload.error">{{upload.msg}}</small>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div slot="modal-footer" class="w-100">
      <span class="float-left m-0">
        <!--<b-btn @click.prevent="start" variant="primary" v-if="!uploadGroup.uploading && !uploadGroup.uploaded && !uploadGroup.error" class="float-left">開始</b-btn>-->
        <b-btn @click.prevent="stopUpload" variant="danger" v-if="uploadGroup.uploading" class="float-left">停止</b-btn>
      </span>
      <span class="float-right m-0">
        <b-btn @click.prevent="retry" variant="primary" v-if="uploadGroup.error" class="float-right">リトライ</b-btn>
      </span>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'UploadProgressModal',
  data () {
    return {
      idx: -1
    }
  },
  methods: {
    start () {
      if (this.$getComponent('App').uploadGroups.filter((v) => v.uploading).length < 2) {
        this.$parent.startUploadGroup(this.idx)
      } else {
        this.$snotify.error('すでに２つのアップロードが行わている');
      }
    },
    retry () {
      this.$parent.startUploadGroup(this.idx)
    },
    stopUpload () {
      this.$parent.cancelUpload(this.idx, ' ユーザによって停止された')
    },
    modalOpen () {
      // TODO
    }
  },
  computed: {
    uploads () {
      if (this.idx > -1) {
        let c = this.$getComponent('App');
        if (this.idx < c.uploadGroups.length) {
          return c.uploadGroups[this.idx].uploads;
        }
      }
      return [];
    },
    uploadGroup () {
      if (this.idx > -1) {
        let c = this.$getComponent('App');
        if (this.idx < c.uploadGroups.length) {
          return c.uploadGroups[this.idx];
        }
      }
      return {};
    }
  },
  mounted () {
    this.$root.$on('openUploadProgressModal', (idx) => {
      this.idx = idx;
      if (this.$refs.uploadModalProgress) {
        this.$refs.uploadModalProgress.show();
      }
    });
  }
}
</script>

<style scoped>

</style>
