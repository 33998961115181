<template>
    <canvas
        ref="canvas"
        width="200"
        height="250"
    />
</template>

<script>
export default {
    name: 'OnePointMiniPlot',
    props: {
        plot: Object,
        scale: {
            type: Number,
            default: 0.2
        }
    },
    data () {
        return {
            timeOut: 0,
            symbolSize: 0.003,
            ctx: null
        }
    },
    watch: {
        gameData () {
            clearTimeout(this.timeOut)
            this.timeOut = setTimeout(() => {
                this.draw()
            }, 500)
        }
    },
    mounted () {
        this.$refs.canvas.width = this.$refs.canvas.width * this.scale
        this.$refs.canvas.height = this.$refs.canvas.height * this.scale
        this.ctx = this.$refs.canvas.getContext('2d')
        this.ctx.scale(this.scale, this.scale)

        setTimeout(() => {
            this.draw()
        }, 100)
    },
    methods: {
        draw () {
            this.ctx.fillStyle = 'white'
            this.ctx.fillRect(0, 0, 200, 250)
            this.ctx.fillStyle = 'black'
            this.ctx.beginPath()
            // this.ctx.clearRect(0, 0, 200, 250)
            this.ctx.strokeStyle = 'gray'
            this.ctx.lineWidth = 1
            this.ctx.strokeRect(0, 0, 200, 250)
            this.ctx.stroke()
            this.ctx.closePath()
            this.ctx.beginPath()
            this.ctx.strokeStyle = 'black'
            this.ctx.lineWidth = 2
            this.ctx.strokeRect(40, 50, 120, 150)
            this.ctx.stroke()
            this.ctx.closePath()

            if (this.plot && this.plot.course_x && this.plot.course_y) {
                this.ctx.beginPath()
                this.ctx.strokeStyle = 'black'
                this.ctx.lineWidth = 1
                this.ctx.arc(this.plot.course_x, this.plot.course_y, 10, 0, 2 * Math.PI)
                this.ctx.fill()
                this.ctx.stroke()
                this.ctx.closePath()
            }
        }
    },
    beforeDestroy () {
        clearTimeout(this.timeOut)
        if (this.ctx) {
            this.ctx.width = 0
            this.ctx.height = 0
            this.ctx = null
        }
    }
}
</script>

<style scoped>
canvas {
    width: 2rem;
    height: auto;
}
</style>
