import Vue from 'vue'
import Router from 'vue-router'
import Download from '@/components/Download'
import Home from '@/components/Home'
import Terms from '@/components/Terms'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/download/:hashid',
            name: 'Download',
            component: Download
        },
        {
            path: '/terms',
            name: 'Terms',
            component: Terms
        }
    ]
})
