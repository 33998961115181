<template>
    <header>
        <!-- Fixed navbar -->
        <b-navbar toggleable="md" type="dark" variant="dark" fixed="top">
            <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
            <b-navbar-brand href="#"><img src="@/assets/img/eve_logo.png" class="logo">EVE<span
                class="d-none d-md-inline"> Baseball</span> <span v-if="this.loggedIn">:: {{ user.group.name }}</span>
            </b-navbar-brand>
            <b-collapse is-nav id="nav_collapse">
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="#" right v-if="this.loggedIn && (user.is_manager || user.is_staff)"
                                @click.prevent="$refs.newsModal.show()">お知らせを作成
                    </b-nav-item>
                    <b-nav-item-dropdown right v-if="this.loggedIn && user.is_manager && !$md.mobile()">
                        <!-- Using button-content slot -->
                        <template slot="button-content">
                            <span>アップロード状況 <b-badge variant="primary"
                                                    v-html="$parent.uploadGroups.length"></b-badge></span>
                        </template>
                        <b-dd-item v-for="(upload, key) in $parent.uploadGroups" :key="key"
                                   @click="showProgress(key, $event)" style="min-width: 300px;">
                            {{ upload.name }}<br>
                            <b-progress>
                                <b-progress-bar :value="upload.progress" :label="upload.progress + '%'" show-progress
                                                :animated="upload.uploading"
                                                :variant="upload.error ? 'danger': (upload.uploaded ? 'success':'primary')"/>
                            </b-progress>
                            <div>
                <span class="float-left m-0 mt-1">
                  <b-btn @click.prevent="stopUpload(key)" variant="danger" v-if="upload.uploading" size="sm"
                         class="float-left">停止</b-btn>
                  <small class="text-muted" v-if="upload.error">{{ upload.msg }}</small>
                </span>
                                <span class="float-right m-0 mt-1">
                  <b-btn @click.prevent="retry(key)" variant="primary" v-if="upload.error" size="sm"
                         class="float-right">リトライ</b-btn>
                </span>
                            </div>
                        </b-dd-item>
                        <!--
                        <b-dd-divider></b-dd-divider>
                        <b-dd-item>ログアウト中止</b-dd-item>
                        -->
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown right v-if="loggedIn">
                        <!-- Using button-content slot -->
                        <template slot="button-content">
                            <strong v-if="user">{{ user.name }}</strong>
                            <b-avatar></b-avatar>
                        </template>
                        <b-dd-item v-b-modal.password>パスワード変更</b-dd-item>
                        <b-dd-divider></b-dd-divider>
                        <b-dd-item @click="logout">ログアウト</b-dd-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <b-modal id="password" title="パスワード変更" size="sm" centered ref="passwordModal" header-close-label="閉じる"
                 cancel-title="キャンセル" ok-title="送信" @ok.prevent="onSubmitPassword" @shown="show">
            <b-form @submit.prevent="onSubmitPassword" ref="form">
                <b-form-group label="現在のパスワード"
                              label-for="current-pass">
                    <b-form-input id="current-pass"
                                  type="password"
                                  v-model="form.old"
                                  required
                                  placeholder="現在のパスワード">
                    </b-form-input>
                </b-form-group>
                <input type="submit" style="visibility: hidden;height: 0">
                <b-form-group label="新しいパスワード"
                              label-for="login-password">
                    <b-form-input id="new-password"
                                  type="password"
                                  v-model="form.password"
                                  required
                                  min-length="8"
                                  description="パスワードが必ず８文字以上"
                                  placeholder="新しいパスワード">
                    </b-form-input>
                    <b-form-invalid-feedback :state="form.password && form.password.length > 7">
                        パスワードが必ず８文字以上
                    </b-form-invalid-feedback>
                    <b-form-input id="new-password-2"
                                  type="password"
                                  v-model="form.password2"
                                  min-length="8"
                                  required
                                  placeholder="新しいパスワードの確認">
                    </b-form-input>
                    <b-form-invalid-feedback :state="form.password && form.password === form.password2">
                        上のパスワードと一致しません
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </b-modal>
        <b-modal id="news" title="お知らせ" centered ref="newsModal" header-close-label="閉じる" cancel-title="キャンセル"
                 ok-title="送信" @ok="onSubmitNews" @shown="showNews"
                 v-if="this.loggedIn && (user.is_manager || user.is_staff)">
            <b-form @submit.prevent="onSubmitNews" ref="formNews">
                <b-form-group label="タイトル"
                              label-for="news-title">
                    <b-form-input id="news-title"
                                  name="title"
                                  type="text"
                                  min-length="3"
                                  max-length="100"
                                  v-model="formNews.title"
                                  required
                                  placeholder="お知らせのタイトル">
                    </b-form-input>
                </b-form-group>
                <b-form-group label="チーム" v-if="user.is_staff"
                              label-for="news-group">
                    <b-form-select id="news-group" v-model="formNews.gid" :options="teams"></b-form-select>
                </b-form-group>
                <b-form-group label="お知らせの期限" label-for="news-expire" description="この日付までこのお知らせを表示する">
                    <datepicker v-model="formNews.expire" :disabledDates="disabledDates" bootstrap-styling
                                calendar-button
                                calendar-button-icon="icon ion-md-calendar" :language="ja" format="yyyy-MM-dd" required
                                ref="newsExpire"></datepicker>
                </b-form-group>
                <b-form-group label="お知らせの内容"
                              label-for="news-body">
                    <b-form-textarea id="new-body"
                                     rows="4"
                                     name="body"
                                     min-length="3"
                                     v-model="formNews.body"
                                     required
                                     placeholder="お知らせの内容">
                    </b-form-textarea>
                </b-form-group>
                <b-form-checkbox v-if="user.is_staff"
                                 id="manager-only"
                                 v-model="formNews.manager_only"
                                 name="manager_only"
                                 value="1"
                                 unchecked-value="0">
                    マネージャー向けのみ
                </b-form-checkbox>
                <input type="submit" style="visibility: hidden; height: 0">
            </b-form>
        </b-modal>
    </header>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {en, ja} from 'vuejs-datepicker/dist/locale'

export default {
    name: 'Header',
    components: {
        Datepicker
    },
    data() {
        let $this = this;
        return {
            ja: ja,
            en: en,
            disabledDates: {
                customPredictor(date) {
                    return !$this.$moment(date).isBetween(new Date(), $this.$moment().add(3, 'months'));
                }
            },
            groupName: '',
            form: {},
            formNews: {},
            teams: []
        }
    },
    computed: {
        user() {
            return this.$ls.get('user')
        },
        loggedIn() {
            return this.$loggedIn() === true
        }
    },
    methods: {
        retry(idx) {
            this.$root.$emit('startUpload', idx)
        },
        stopUpload(idx) {
            this.$root.$emit('stopUpload', idx)
        },
        onSubmitNews(e) {
            let $this = this;
            e.preventDefault();
            if (this.formNews.title && this.formNews.body && this.formNews.expire) {
                if (this.formNews.title.length >= 3 && this.formNews.body.length >= 3) {
                    let data = {...this.formNews}
                    data.expire = this.$moment(data.expire).format('YYYY-MM-DD')
                    this.$http.post('news/0', data).then(function (response) {
                        $this.$root.$emit('endSpinner');
                        if (response) {
                            $this.$refs.newsModal.hide();
                            $this.$root.$emit('loadNews');
                        }
                    }).catch(function (error) {
                        $this.$root.$emit('endSpinner');
                        if (error.response) {
                            switch (error.response.status) {
                                case 404:
                                    $this.$snotify.error('指定されたお知らせが存在しない');
                                    break;
                                default:
                                    if (error.response.data && error.response.data.error) {
                                        $this.$snotify.error(error.response.data.error);
                                    } else {
                                        $this.$snotify.error('サーバーエラー');
                                    }
                                    break;
                            }
                        }
                    })
                }
            }
        },
        logout() {
            let u = this.$parent.uploading ? 'アップロード中です、' : '';
            this.$swal({
                title: 'ログアウト',
                text: u + 'ログアウトしますか？',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonText: 'キャンセル',
                confirmButtonText: 'ログアウト'
            }).then((result) => {
                if (result.value) {
                    this.$http.post('logout', {
                        guid: this.$guid()
                    }).then((response) => {
                        console.log(response)
                        this.$root.$emit('endSpinner');
                        this.$ls.remove('password');
                        this.$ls.remove('user');
                        this.$ls.remove('token');
                        this.$ls.remove('news-g');
                        this.$ls.remove('news-s');
                        this.$ls.remove('uploadAcl');
                        /* eslint-disable no-undef */
                        this.$log( 'logout');
                        gtag('set', {'user_id': null});
                        if (typeof Android !== 'undefined') {
                            Android.reload();
                        } else {
                            window.location.reload();
                        }
                    }).catch((error) => {
                        this.$root.$emit('endSpinner');
                        if (error.response) {
                            if (error.response.data && error.response.data.error) {
                                this.$snotify.error(error.response.data.error);
                            } else {
                                this.$snotify.error('サーバーエラー');
                            }
                        }
                    })
                }
            })
        },
        show() {
            this.$refs.form.reset()
            this.form = {}
        },
        showNews() {
            this.$refs.formNews.reset()
            this.formNews = {}
        },
        onSubmitPassword(e) {
            e.preventDefault();
            this.$refs.form.checkValidity()
            if (this.form.old && this.form.password && this.form.password2) {
                if (this.form.password.length >= 8 && this.form.password === this.form.password2) {
                    this.$http.post('user/password', {
                        old: this.form.old,
                        password: this.form.password
                    }).then((response) => {
                        this.$root.$emit('endSpinner');
                        if (response) {
                            if (this.$ls.get('password', null)) {
                                this.$ls.set('password', response.data.password);
                            }
                            this.$refs.passwordModal.hide();
                            /* eslint-disable no-undef */
                            this.$log( 'change_password');
                        }
                    }).catch((error) => {
                        this.$root.$emit('endSpinner');
                        if (error.response) {
                            switch (error.response.status) {
                                case 404:
                                    this.$snotify.error('ユーザー名又はパスワードが間違っています');
                                    break;
                                default:
                                    if (error.response.data && error.response.data.error) {
                                        this.$snotify.error(error.response.data.error);
                                    } else {
                                        this.$snotify.error('サーバーエラー');
                                    }
                                    break;
                            }
                        }
                    })
                }
            }
        },
        showProgress(idx, e) {
            if (e.target.tagName.toLowerCase() === 'button') {
                return;
            }
            this.$root.$emit('openUploadProgressModal', idx);
        }
    },
    mounted() {
        if (this.loggedIn && (this.user.is_manager || this.user.is_staff)) {
            this.$http.get('group/').then((r) => {
                if (r) {
                    let l = r.data.groups.map(item => {
                        return {text: item.name, value: item.group_id}
                    });
                    l.unshift({
                        text: 'すべて', value: null
                    })
                    this.teams = l;
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log(error.config);
                }
            })
        }
    }
}
</script>

<style scoped>
.logo {
    height: 32px;
    margin-right: 10px;
}
</style>
