<template>
    <b-modal id="modalUpload" title="映像をアップロード" size="lg" ref="uploadModal" cancel-title="キャンセル" ok-title="アップロード開始"
             ok-title-html="<i class='icon ion-md-cloud-upload'></i> アップロード開始" @ok="startUpload" @shown="modalOpen"
             no-close-on-backdrop>
        <b-overlay
            id="overlay-background"
            :show="isLoading"
            variant="white"
            :opacity="0.9"
            rounded="sm"
        >
        <b-form @submit.prevent ref="uploadForm">
            <b-form-group label="タイトル" label-for="upload-title" description="後で検索できるようなタイトルしてください">
                <b-form-input type="text" id="upload-title" placeholder="映像のタイトルを入力してください" v-model="form.name"
                              ref="title" required></b-form-input>
            </b-form-group>
            <div class="form-row">
                <div class="col-5">
                    <div class="form-row">
                        <div class="col">
                            <b-form-group label="日付" label-for="upload-date" description="撮影日">
                                <datepicker v-model="form.shot_date" :disabledDates="$parent.datePicker.disabledDates"
                                            bootstrap-styling ref="date"
                                            calendar-button :calendar-button-icon="$parent.datePicker.icon"
                                            :language="$parent.datePicker.language" :format="$parent.datePicker.format"
                                            required></datepicker>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <b-form-group label="詳細" label-for="upload-description" description="映像に対する詳細、メモなど">
                                <b-form-textarea type="text" id="upload-description" placeholder=""
                                                 v-model="form.description" :rows="$env().VUE_APP_NOSHARE?10:2" :max-rows="$env().VUE_APP_NOSHARE?10:3"
                                                 ref="desc"></b-form-textarea>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row" v-if="!$env().VUE_APP_NOSHARE">
                        <div class="col">
                            <b-form-group :label="'閲覧権限'+aclSelected">
                                <div style="max-height: 280px; overflow-y: auto;" class="px-1">
                                    <b-form-checkbox-group
                                        id="teams"
                                        name="teams"
                                        stacked
                                        value-field="group_id"
                                        text-field="name"
                                        v-model="acls" :options="teams"/>
                                </div>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row" v-if="!$env().VUE_APP_NOSHARE">
                        <div class="col">
                            <b-btn size="sm" block @click="checkAll" variant="outline-success">すべてをチェック</b-btn>
                        </div>
                        <div class="col">
                            <b-btn size="sm" block @click="unCheckAll" variant="outline-danger">すべてを外す</b-btn>
                        </div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="card" ref="fileListCard" @drop.prevent="dropFile" @dragenter.prevent="dragEnter"
                         @dragleave.prevent="dragLeave" @dragover.prevent="dragOver">
                        <div class="card-header">
                            <label for="upload-file" class="float-right">
                                <b-btn variant="info" size="sm" @click="fileSelect" class="ml-2"><i
                                    class="icon ion-md-document"></i> ファイル選択
                                    <input ref="file" type="file" id="upload-file" style="width: 0;" class="inputfile"
                                           @click.stop multiple @change="fileSelected" :accept="accepts">
                                </b-btn>
                            </label>
                            <span class="card-title small">ここにファイルをドラグ＆ドロップするか右のボタンでファイルを追加してください</span>
                        </div>
                        <div class="card-body" style="min-height: 400px; max-height: 500px; overflow-y: auto;">
                            <draggable tag="ul" class="list-group" v-model="files" @start="drag=true" @end="drag=false"
                                       handle=".ion-md-reorder" v-bind="dragOptions">
                                <li v-for="file in files" :key="file.id" class="list-group-item">
                                    <i class="icon ion-md-reorder float-left mr-3 mt-2"></i> {{ file.name }}
                                    <span class="badge badge-warning" v-if="file.warning" v-html="file.warning"></span>
                                    <br>
                                    <span class="badge badge-info">{{ file.hsize }}</span>
                                    <b-btn variant="danger" size="sm" class="float-right" @click="delFile(file.id)">削除
                                    </b-btn>
                                </li>
                            </draggable>
                        </div>
                        <div class="card-footer">
                            <span class="text-muted small">並び替えできます</span><span class="float-right">
              <b-btn size="sm" variant="warning" @click.prevent="sortByName">ファイル名順で並び替え</b-btn>
            </span>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
        </b-overlay>
        <div slot="modal-footer" class="w-100">
            <span class="float-left m-0">
                <b-form-checkbox id="agree" ref="agree" value="agree" unchecked-value="no" v-model="agree">私はこの<router-link
                    to="/terms" class="underline" target="_blank">サイトの規約</router-link>に同意します</b-form-checkbox>
                <b-popover
                    target="agree"
                    placement="topright"
                    content="アップロードするには本サイトの規約を同意してください"
                />
            </span>
            <span class="float-right m-0">
                <b-button variant="secondary mr-1" @click="$refs.uploadModal.hide()" :disabled="isLoading">キャンセル</b-button>
                <b-button variant="primary" @click="startUpload" :disabled="isLoading"><i class='icon ion-md-cloud-upload'></i> アップロード開始
                    <span style="display: none;" v-if="isLoading">
                        <span style="vertical-align: middle;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                    </span>
                </b-button>
            </span>
        </div>
    </b-modal>
</template>

<script>
import fs from 'filesize'
import draggable from 'vuedraggable';
import Datepicker from 'vuejs-datepicker';
import {settings} from '@/variables'
import SparkMD5 from 'spark-md5'

export default {
    components: {
        draggable,
        Datepicker
    },
    name: 'UploadModal',
    props: {
        teams: Array
    },
    data() {
        return {
            agree: 0,
            form: {},
            accepts: settings.acceptExt.join(','),
            acls: [],
            files: [],
            dragOptions: {
                animation: 200,
                group: 'files',
                disabled: false,
                ghostClass: 'ghost'
            },
            isLoading: false
        }
    },
    computed: {
        aclSelected() {
            return this.acls.length?'（'+this.acls.length+'件選択中）':''
        },
        contractTeam() {
            return this.teams.filter(x => x.contract)
        }
    },
    methods: {
        sortByName() {
            this.files.sort((a, b) => {
                let aa = a.name.toUpperCase();
                let bb = b.name.toUpperCase();
                if (aa < bb) {
                    return -1;
                }
                if (aa > bb) {
                    return 1;
                }
                return 0;
            })
        },
        unCheckAll() {
            this.acls = [];
        },
        checkAll() {
            let a = [];
            for (let i = 0; i < this.teams.length; i++) {
                a.push(this.teams[i].group_id)
            }
            this.acls = a;
        },
        addFiles(files) {
            let notAdded = [];
            Array.from(files).forEach(f => {
                if (f.size < 5368709120) {
                    let id = SparkMD5.hash(f.name + '|' + f.size)
                    if (this.files.map(item => item.id).indexOf(id) > -1) {
                        return;
                    }
                    f.id = id;
                    f.hsize = fs(f.size)
                    if (f.name.toLowerCase().endsWith('.vob') && f.size < 1024 * 1024) {
                        f.warning = 'おそらくDVDメニューです、外してください'
                    }
                    if (f.name.toLowerCase().endsWith('ts') && f.size < 100 * 1024) {
                        f.warning = 'おそらくDVDメニューです、外してください'
                    }
                    this.files.push(f);
                } else {
                    notAdded.push(f.name)
                }
            })
            if (notAdded.length) {
                if (notAdded.length < 6) {
                    this.$snotify.error('ファイルが大きすぎるための以下のファイルが追加できない：\n' + notAdded.join(', ')+'。ガッタイツールを使ってからアップロードしてください。');
                } else {
                    this.$snotify.error('ファイルが大きすぎるための一部のファイルが追加できないファイルです。ガッタイツールを使ってからアップロードしてください。');
                }
            }
            const size = this.files.reduce((a,c) => a+c.size,0)
            if (size >= 2147483648) {
                this.$snotify.error('合計ファイルサイズが推奨サイズより大きいため、ガッタイツールを使うのがおすすめです。');
            }
        },
        dropFile(ev) {
            this.$refs.fileListCard.classList.remove('shadow-lg');
            let files = [];
            let notAdded = [];
            if (ev.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        let f = ev.dataTransfer.items[i].getAsFile();
                        let ext = '.' + f.name.toLowerCase().split('.').pop();
                        if ((/^video\/.+/.test(f.type) || settings.acceptExt.indexOf(ext) > -1)) {
                            files.push(f);
                        } else {
                            notAdded.push(f.name)
                        }
                    }
                }
            } else {
                // Use DataTransfer interface to access the file(s)
                for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                    let f = ev.dataTransfer.files[i];
                    let ext = '.' + f.name.toLowerCase().split('.').pop();
                    if (/^video\/.+/.test(f.type) || settings.acceptExt.indexOf(ext) > -1) {
                        files.push(f);
                    } else {
                        notAdded.push(f.name)
                    }
                }
            }
            if (files.length) {
                this.addFiles(files);
            }
            if (notAdded.length) {
                if (notAdded.length < 6) {
                    this.$snotify.error('以下のファイルが追加できない：\n' + notAdded.join(', '));
                } else {
                    this.$snotify.error('一部のファイルが追加できないファイルです');
                }
            }
        },
        dragOver(e) {
            let acceptFile = true;
            if (e.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (e.dataTransfer.items[i].kind !== 'file') {
                        acceptFile = false;
                        break;
                    }
                }
            } else {
                if (e.dataTransfer.files.length) {
                    // Use DataTransfer interface to access the file(s)
                    for (let i = 0; i < e.dataTransfer.files.length; i++) {
                        let f = e.dataTransfer.files[i];
                        let ext = '.' + f.name.toLowerCase().split('.').pop();
                        if (!/^video\/.+/.test(f.type) || settings.acceptExt.indexOf(ext) < 0) {
                            acceptFile = false;
                            break;
                        }
                    }
                } else {
                    acceptFile = false;
                }
            }
            e.dataTransfer.dropEffect = acceptFile ? 'copy' : 'none';
            if (acceptFile) {
                this.$refs.fileListCard.classList.add('shadow-lg');
            }
        },
        dragEnter(e) {
            let acceptFile = true;
            if (e.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (e.dataTransfer.items[i].kind !== 'file') {
                        acceptFile = false;
                        break;
                    }
                }
            } else {
                // Use DataTransfer interface to access the file(s)
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    let f = e.dataTransfer.files[i];
                    let ext = '.' + f.name.toLowerCase().split('.').pop();
                    if (!/^video\/.+/.test(f.type) || settings.acceptExt.indexOf(ext) < 0) {
                        acceptFile = false;
                        break;
                    }
                }
            }
            e.dataTransfer.dropEffect = acceptFile ? 'copy' : 'none';
            this.$refs.fileListCard.classList.add('shadow-lg');
        },
        dragLeave(e) {
            e.dataTransfer.dropEffect = 'none';
            this.$refs.fileListCard.classList.remove('shadow-lg');
            //
        },
        startUpload(e) {
            e.preventDefault();
            if (!this.form.name || !this.form.description || !this.form.shot_date) {
                if (!this.form.name) {
                    this.$refs.title.$el.focus();
                    return;
                }
                if (!this.form.shot_date) {
                    this.$refs.date.showCalendar();
                    return;
                }
                if (!this.form.description) {
                    this.$refs.desc.focus();
                    return;
                }
            }
            if (this.files.length === 0) {
                return;
            }
            if (this.agree !== 'agree') {
                this.$refs.agree.$el.firstChild.focus();
                this.$root.$emit('bv::show::popover', 'agree')
                return;
            }

            const size = this.files.reduce((a,c) => a+c.size,0)
            if (size >= 5368709120 * (this.$env().VUE_APP_NOSHARE ? 1 : 2)) {
                this.$snotify.error('合計ファイルサイズが大きすぎるためアップロード不可能です。ガッタイツールを使ってからアップロードしてください。');
                return
            }

            this.isLoading = true
            let data = {
                name: this.form.name,
                description: this.form.description ? this.form.description : '',
                shot_date: this.$moment(this.form.shot_date).format('YYYY-MM-DD'),
                acl: this.acls
            };
            console.log(data);
            // eslint-disable-next-line no-unreachable
            this.$http.post('video/0', data).then((response) => {
                console.log(response.data);
                let vid = response.data.video.video_id;
                let uploads = [];
                for (let idx = 0; idx < this.files.length; idx++) {
                    let file = this.files[idx];
                    uploads.push({
                        uploadId: 0,
                        id: file.id,
                        name: file.name,
                        file: file,
                        chunk: (idx + 1),
                        chunks: this.files.length,
                        uploading: false,
                        uploaded: false,
                        progress: 0,
                        error: false,
                        msg: '',
                        vid: vid
                    })
                }
                this.$refs.uploadModal.hide();
                this.$root.$emit('updateTable');
                this.$ls.set('uploadAcl', data.acl);
                /* eslint-disable no-undef */
                this.$log( 'upload', {'event_label': data.name});
                this.$parent.startUpload(data.name, vid, uploads);
                this.files = null
                this.files = []
            }).catch((error) => {
                if (this.$ab) {
                    this.$ab.notify(error);
                }
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            this.$loggedIn = false;
                            break;
                        case 403:
                            this.$snotify.error('削除する権限がありません');
                            break;
                        default:
                            if (error.response.data && error.response.data.error) {
                                this.$snotify.error(error.response.data.error);
                            } else {
                                this.$snotify.error('サーバーエラー');
                            }
                            break;
                    }
                }
            }).finally(() => {
                this.isLoading = false
            })
        },
        fileSelect() {
            this.$refs.file.value = '';
            this.$refs.file.click();
        },
        fileSelected() {
            this.addFiles(this.$refs.file.files)
        },
        delFile(key) {
            let i = this.files.map(item => item.id).indexOf(key);
            this.files.splice(i, 1);
        },
        modalOpen() {
            this.form = {};
            this.files = [];
            this.agree = 0;
            this.acls = [] // this.$ls.get('uploadAcl', []);
            this.isLoading = false
        }
    },
    mounted() {
        this.$root.$on('openUploadModal', () => {
            if (this.$refs.uploadModal) {
                this.$refs.uploadModal.show();
            }
        });
        // this.$refs.file.addEventListener('change', this.fileSelected, false)
    }

}
</script>

<style>
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.dragEnter {
    cursor: copy;
}

.dragLeave {
    cursor: default;
}
</style>

<style scoped>
.underline {
    text-decoration: underline;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.ion-md-reorder {
    cursor: row-resize;
}
</style>
