export const settings = {
  acceptExt: ['.avi', '.mp4', '.mpg', '.mkv', '.mts', '.m2ts', '.mpeg', '.mpv', '.mov', '.wmv', '.asf', '.vob', '.vro', 'video/*'],
  maxDownload: 5,
  gtag: 'UA-136740756-1',
  nextFocusUpdate: 120000,
  sessionTimeOut: 86400000
}

// 厳密には違うけど、一旦これで。
// 正確には、jsonインポートするときに、球種マスタも一緒に取り込む
export const ballTypeFlag = {
  "1": {
    name: "ストレート",
    name_e: "Straight"
  },
  "2": {
    name: "シュート",
    name_e: "Shoot"
  },
  "3": {
    name: "カット",
    name_e: "Cutball"
  },
  "4": {
    name: "スライダー",
    name_e: "Slider"
  },
  "5": {
    name: "カーブ",
    name_e: "Curve"
  },
  "6": {
    name: "フォーク",
    name_e: "Fork"
  },
  "7": {
    name: "シンカー",
    name_e: "Sinker"
  },
  "8": {
    name: "チェンジアップ",
    name_e: "Change Up"
  },
  "9": {
    name: "特殊",
    name_e: "Special"
  },
  "10": {
    name: "ツーシーム",
    name_e: "Two Seam"
  },
  "11": {
    name: "ナックル",
    name_e: "Knuckle"
  },
}

/* eslint-disable */
export const results = {
  "result-0": {
    "_id": "result-0",
    "name": "なし",
    "last_id": "12",
    "show_index": 99,
    "name_s": "なし",
    "name_e": "Non",
    "name_es": "Non",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "0",
    "list_x": 0,
    "list_y": 0,
    "list_color": "black",
    "scope_color": "green",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": false,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-1": {
    "_id": "result-1",
    "name": "ファウル",
    "last_id": "4",
    "show_index": 99,
    "name_s": "ファウル",
    "name_e": "Foul",
    "name_es": "Foul",
    "stats_id": 5,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "1",
    "list_x": 1,
    "list_y": 4,
    "list_color": "silver",
    "scope_color": "yellow",
    "bat_color": "yellow",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": true,
    "last_ball": false,
    "strike": true,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-2": {
    "_id": "result-2",
    "name": "単打",
    "last_id": "1",
    "show_index": 99,
    "name_s": "単打",
    "name_e": "Single",
    "name_es": "Single",
    "stats_id": 1,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 2,
    "list_y": 1,
    "list_color": "navy",
    "scope_color": "navy",
    "bat_color": "navy",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-3": {
    "_id": "result-3",
    "name": "死球",
    "last_id": "10",
    "show_index": 99,
    "name_s": "死球",
    "name_e": "Hit by Pitch",
    "name_es": "HBP",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "5",
    "list_x": 5,
    "list_y": 1,
    "list_color": "olive",
    "scope_color": "aqua",
    "bat_color": "aqua",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": false,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-4": {
    "_id": "result-4",
    "name": "二塁打",
    "last_id": "1",
    "show_index": 99,
    "name_s": "二塁打",
    "name_e": "Double",
    "name_es": "2B",
    "stats_id": 1,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 2,
    "list_y": 2,
    "list_color": "navy",
    "scope_color": "navy",
    "bat_color": "navy",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 2,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-5": {
    "_id": "result-5",
    "name": "エンタイトル２ベース",
    "last_id": "1",
    "show_index": 99,
    "name_s": "ｴﾝﾀｲﾄﾙ2B",
    "name_e": "Ground-Rule Double",
    "name_es": "Ground-Rule 2B",
    "stats_id": 1,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 2,
    "list_y": 3,
    "list_color": "navy",
    "scope_color": "navy",
    "bat_color": "navy",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 2,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-6": {
    "_id": "result-6",
    "name": "三塁打",
    "last_id": "1",
    "show_index": 99,
    "name_s": "三塁打",
    "name_e": "Triple",
    "name_es": "3B",
    "stats_id": 1,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 3,
    "list_y": 1,
    "list_color": "navy",
    "scope_color": "navy",
    "bat_color": "navy",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 3,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-7": {
    "_id": "result-7",
    "name": "本塁打",
    "last_id": "1",
    "show_index": 99,
    "name_s": "本塁打",
    "name_e": "Home Run",
    "name_es": "HR",
    "stats_id": 1,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 3,
    "list_y": 2,
    "list_color": "navy",
    "scope_color": "navy",
    "bat_color": "navy",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 4,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-8": {
    "_id": "result-8",
    "name": "失策出塁",
    "last_id": "5",
    "show_index": 99,
    "name_s": "失策出塁",
    "name_e": "Base on Error",
    "name_es": "Base on Err",
    "stats_id": 2,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 1,
    "list_y": 3,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-9": {
    "_id": "result-9",
    "name": "凡打",
    "last_id": "2",
    "show_index": 99,
    "name_s": "凡打",
    "name_e": "Out",
    "name_es": "Out",
    "stats_id": 2,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 1,
    "list_y": 1,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-10": {
    "_id": "result-10",
    "name": "犠打",
    "last_id": "6",
    "show_index": 99,
    "name_s": "犠打",
    "name_e": "Sacrifice Hit",
    "name_es": "SH",
    "stats_id": 6,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": false,
    "list_id": "5",
    "list_x": 4,
    "list_y": 1,
    "list_color": "lime",
    "scope_color": "green",
    "bat_color": "green",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": true,
    "type": "result",
    "division": "1"
  },
  "result-11": {
    "_id": "result-11",
    "name": "犠飛",
    "last_id": "7",
    "show_index": 99,
    "name_s": "犠飛",
    "name_e": "Sacrifice Fly",
    "name_es": "SF",
    "stats_id": 6,
    "bat_ground_flg": false,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 4,
    "list_y": 2,
    "list_color": "lime",
    "scope_color": "green",
    "bat_color": "green",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": true,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-12": {
    "_id": "result-12",
    "name": "空振",
    "last_id": "3",
    "show_index": 99,
    "name_s": "空振",
    "name_e": "Swing Missed",
    "name_es": "Swing Missed",
    "stats_id": 3,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "1",
    "list_x": 1,
    "list_y": 1,
    "list_color": "gray",
    "scope_color": "teal",
    "bat_color": "lime",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": true,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-13": {
    "_id": "result-13",
    "name": "見送",
    "last_id": "3",
    "show_index": 99,
    "name_s": "見送",
    "name_e": "Strike Looking",
    "name_es": "Strike Looking",
    "stats_id": 4,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "1",
    "list_x": 1,
    "list_y": 2,
    "list_color": "gray",
    "scope_color": "teal",
    "bat_color": "olive",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": true,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-14": {
    "_id": "result-14",
    "name": "ボール",
    "last_id": "9",
    "show_index": 99,
    "name_s": "ボール",
    "name_e": "Ball",
    "name_es": "Ball",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "1",
    "list_x": 1,
    "list_y": 3,
    "list_color": "purple",
    "scope_color": "aqua",
    "bat_color": "aqua",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": true,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "4"
  },
  "result-15": {
    "_id": "result-15",
    "name": "四球",
    "last_id": "9",
    "show_index": 99,
    "name_s": "四球",
    "name_e": "Base on Ball",
    "name_es": "BB",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "0",
    "list_x": 0,
    "list_y": 0,
    "list_color": "black",
    "scope_color": "aqua",
    "bat_color": "aqua",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": false,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": false,
    "last_ball": true,
    "strike": false,
    "ball": true,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-16": {
    "_id": "result-16",
    "name": "邪飛",
    "last_id": "4",
    "show_index": 99,
    "name_s": "邪飛",
    "name_e": "Foul Out",
    "name_es": "Foul Out",
    "stats_id": 2,
    "bat_ground_flg": false,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 1,
    "list_y": 2,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": true,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-17": {
    "_id": "result-17",
    "name": "牽制",
    "last_id": "12",
    "show_index": 99,
    "name_s": "牽制",
    "name_e": "Pickoff Throw",
    "name_es": "PK Throw",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "0",
    "list_x": 0,
    "list_y": 0,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": false,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "4"
  },
  "result-18": {
    "_id": "result-18",
    "name": "盗塁",
    "last_id": "12",
    "show_index": 99,
    "name_s": "盗塁",
    "name_e": "Steal",
    "name_es": "Steal",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "0",
    "list_x": 0,
    "list_y": 0,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": false,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "3"
  },
  "result-19": {
    "_id": "result-19",
    "name": "交代",
    "last_id": "12",
    "show_index": 99,
    "name_s": "交代",
    "name_e": "Change Player",
    "name_es": "Change Player",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "0",
    "list_x": 0,
    "list_y": 0,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": false,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "11"
  },
  "result-20": {
    "_id": "result-20",
    "name": "ボーク",
    "last_id": "5",
    "show_index": 99,
    "name_s": "ボーク",
    "name_e": "Balk",
    "name_es": "Balk",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "2",
    "list_x": 1,
    "list_y": 1,
    "list_color": "white",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": true,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 1,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "4"
  },
  "result-21": {
    "_id": "result-21",
    "name": "打撃妨害",
    "last_id": "11",
    "show_index": 99,
    "name_s": "打撃妨害",
    "name_e": "Defensive Interference",
    "name_es": "Defensive IF",
    "stats_id": 7,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "5",
    "list_x": 6,
    "list_y": 1,
    "list_color": "black",
    "scope_color": "silver",
    "bat_color": "silver",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": false,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "5"
  },
  "result-22": {
    "_id": "result-22",
    "name": "守備妨害",
    "last_id": "5",
    "show_index": 99,
    "name_s": "守備妨害",
    "name_e": "Offensive Interference",
    "name_es": "Offensive IF",
    "stats_id": 7,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "5",
    "list_x": 6,
    "list_y": 2,
    "list_color": "black",
    "scope_color": "silver",
    "bat_color": "silver",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": true,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "3"
  },
  "result-23": {
    "_id": "result-23",
    "name": "走塁妨害",
    "last_id": "5",
    "show_index": 99,
    "name_s": "走塁妨害",
    "name_e": "Obstruction",
    "name_es": "OB",
    "stats_id": 7,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "5",
    "list_x": 5,
    "list_y": 3,
    "list_color": "black",
    "scope_color": "silver",
    "bat_color": "silver",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": false,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "6"
  },
  "result-24": {
    "_id": "result-24",
    "name": "振り逃げ",
    "last_id": "3",
    "show_index": 99,
    "name_s": "振り逃げ",
    "name_e": "Safe at first on third strike",
    "name_es": "Kd3",
    "stats_id": 3,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "5",
    "list_x": 5,
    "list_y": 2,
    "list_color": "olive",
    "scope_color": "teal",
    "bat_color": "lime",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": false,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-25": {
    "_id": "result-25",
    "name": "タイム",
    "last_id": "12",
    "show_index": 99,
    "name_s": "タイム",
    "name_e": "Time",
    "name_es": "Time",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "9",
    "list_x": 1,
    "list_y": 1,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": true,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "11"
  },
  "result-26": {
    "_id": "result-26",
    "name": "アピールプレー",
    "last_id": "12",
    "show_index": 99,
    "name_s": "アピール",
    "name_e": "Appeal Play",
    "name_es": "Appeal Play",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "9",
    "list_x": 1,
    "list_y": 2,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": true,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "11"
  },
  "result-27": {
    "_id": "result-27",
    "name": "走塁",
    "last_id": "12",
    "show_index": 99,
    "name_s": "走塁",
    "name_e": "Running",
    "name_es": "Running",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "0",
    "list_x": 0,
    "list_y": 0,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": false,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "3"
  },
  "result-28": {
    "_id": "result-28",
    "name": "反則投球",
    "last_id": "5",
    "show_index": 99,
    "name_s": "反則投球",
    "name_e": "Illegal Pitch",
    "name_es": "Illegal Pitch",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "2",
    "list_x": 1,
    "list_y": 2,
    "list_color": "white",
    "scope_color": "aqua",
    "bat_color": "aqua",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": true,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "4"
  },
  "result-29": {
    "_id": "result-29",
    "name": "15秒ルール",
    "last_id": "5",
    "show_index": 99,
    "name_s": "15秒ルール",
    "name_e": "Fifteen-Second Rule",
    "name_es": "15sec Rule",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "2",
    "list_x": 1,
    "list_y": 3,
    "list_color": "white",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": true,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "4"
  },
  "result-30": {
    "_id": "result-30",
    "name": "野手選択",
    "last_id": "2",
    "show_index": 99,
    "name_s": "野選",
    "name_e": "Fielder's Choice",
    "name_es": "FC",
    "stats_id": 2,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 6,
    "list_y": 3,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-31": {
    "_id": "result-31",
    "name": "空振三振",
    "last_id": "3",
    "show_index": 99,
    "name_s": "空三振",
    "name_e": "Strikeout Swinging",
    "name_es": "SO Swinging",
    "stats_id": 3,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "0",
    "list_x": 0,
    "list_y": 0,
    "list_color": "black",
    "scope_color": "teal",
    "bat_color": "lime",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": false,
    "batting_flg": true,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": true,
    "strike": true,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-32": {
    "_id": "result-32",
    "name": "見送三振",
    "last_id": "3",
    "show_index": 99,
    "name_s": "見三振",
    "name_e": "Strikeout Looking",
    "name_es": "SO Looking",
    "stats_id": 4,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "0",
    "list_x": 0,
    "list_y": 0,
    "list_color": "black",
    "scope_color": "teal",
    "bat_color": "olive",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": false,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": true,
    "strike": true,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-33": {
    "_id": "result-33",
    "name": "先発",
    "last_id": "12",
    "show_index": 99,
    "name_s": "先発",
    "name_e": "Starting Lineup",
    "name_es": "Starting Lineup",
    "stats_id": 0,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "0",
    "list_x": 0,
    "list_y": 0,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": false,
    "disp_flg": false,
    "batting_flg": false,
    "bat_yn": false,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": false,
    "last_ball": false,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "11"
  },
  "result-34": {
    "_id": "result-34",
    "name": "犠打失策",
    "last_id": "6",
    "show_index": 99,
    "name_s": "犠打失策",
    "name_e": "Sacrifice Hit and Error",
    "name_es": "SH and E",
    "stats_id": 6,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": false,
    "list_id": "5",
    "list_x": 4,
    "list_y": 3,
    "list_color": "lime",
    "scope_color": "green",
    "bat_color": "green",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": true,
    "type": "result",
    "division": "1"
  },
  "result-35": {
    "_id": "result-35",
    "name": "犠飛失策",
    "last_id": "7",
    "show_index": 99,
    "name_s": "犠飛失策",
    "name_e": "Sacrifice Fly and Error",
    "name_es": "SF and E",
    "stats_id": 6,
    "bat_ground_flg": false,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 4,
    "list_y": 4,
    "list_color": "lime",
    "scope_color": "green",
    "bat_color": "green",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": true,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-36": {
    "_id": "result-36",
    "name": "犠打野選",
    "last_id": "6",
    "show_index": 99,
    "name_s": "犠打野選",
    "name_e": "Sacrifice Hit and Fielder's Choice",
    "name_es": "SH and FC",
    "stats_id": 6,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": false,
    "list_id": "5",
    "list_x": 5,
    "list_y": 4,
    "list_color": "lime",
    "scope_color": "green",
    "bat_color": "green",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": true,
    "type": "result",
    "division": "1"
  },
  "result-37": {
    "_id": "result-37",
    "name": "野選失策",
    "last_id": "2",
    "show_index": 99,
    "name_s": "野選失策",
    "name_e": "Fielder's Choice and Error",
    "name_es": "FC and E",
    "stats_id": 2,
    "bat_ground_flg": true,
    "bat_fly_flg": true,
    "bat_drive_flg": true,
    "list_id": "5",
    "list_x": 6,
    "list_y": 4,
    "list_color": "black",
    "scope_color": "black",
    "bat_color": "black",
    "bat_circle_flg": true,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 1,
    "batruns_flg": true,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": false,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": false,
    "type": "result",
    "division": "1"
  },
  "result-38": {
    "_id": "result-38",
    "name": "スリーバント失敗",
    "last_id": "3",
    "show_index": 99,
    "name_s": "ｽﾘｰﾊﾞﾝﾄ失敗",
    "name_e": "Three Strike Bunt Failed",
    "name_es": "3 Strike Bunt Failed",
    "stats_id": 5,
    "bat_ground_flg": false,
    "bat_fly_flg": false,
    "bat_drive_flg": false,
    "list_id": "5",
    "list_x": 1,
    "list_y": 4,
    "list_color": "black",
    "scope_color": "yellow",
    "bat_color": "yellow",
    "bat_circle_flg": false,
    "bat_fillstyle": 0,
    "bat_drawstyle": 0,
    "pit_flg": true,
    "disp_flg": true,
    "batting_flg": true,
    "bat_yn": true,
    "runs": 0,
    "batruns_flg": false,
    "bat_must_chk": true,
    "last_ball": true,
    "strike": true,
    "ball": false,
    "out": false,
    "tag_up": false,
    "bunt": true,
    "type": "result",
    "division": "1"
  }
}
