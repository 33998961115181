<template>
    <div>
        <div class="row">
            <div class="col-md-9 col-12">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <b-button variant="primary" @click.prevent="addUser"><i class="icon ion-md-person-add"></i> ユーザー追加
                    </b-button>
                    <div class="mr-auto"></div>
                    <form class="form-inline" @submit.prevent>
                        <div class="form-group ml-1 my-2 my-sm-0">
                            <b-form-select v-model="selectedTeam" :options="teams2"
                                           @change="teamChange"></b-form-select>
                        </div>
                    </form>
                </nav>
                <b-table selectable striped hover :items="users" :fields="fields" :current-page="currentPage"
                         primary-key="vid"
                         :per-page="perPage" bordered ref="table" @row-selected="rowSelected"
                         select-mode="single" show-empty empty-text="結果ありません">
                    <template v-slot:cell(id)="data">
                        <div>{{ data.item.username }}</div>
                        <small class="text-info">{{ data.item.last_name }} {{ data.item.first_name }}</small>
                        <b-badge pill variant="secondary" class="float-right" v-if="!data.item.is_active">無効</b-badge>
                        <b-badge pill variant="primary" class="float-right" v-if="data.item.is_manager">マネージャー</b-badge>
                    </template>
                    <template v-slot:cell(created)="data">
                        <div>{{ $moment(data.value).local().calendar() }}</div>
                        <div class="small text-muted">最後のログイン:<br>{{
                                data.item.last_login ? $moment(data.item.last_login).local().calendar() : 'ログインしたことない'
                            }}
                        </div>
                    </template>
                </b-table>
                <b-row>
                    <b-col md="6" class="my-1">
                        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0"/>
                    </b-col>
                    <b-col md="6" class="my-1 text-right">
                        <span>{{ currentRows }} / {{ totalRows }}</span>
                    </b-col>
                </b-row>
            </div>
            <div class="col-md-3 d-none d-md-block">
                <div class="card info-card" id="info-card">
                    <div class="card-body" v-if="selectedItem">
                        <div class="card-text mb-3 small">
                            <div>
                                <span class="font-weight-bold">UID：</span><span class="text-info"
                                                                                v-html="selectedItem.id"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">名前：</span><span class="text-info"
                                                                               v-html="selectedItem.last_name + ' ' + selectedItem.first_name"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">ユーザー名：</span><span class="text-info"
                                                                                  v-html="selectedItem.username"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">チーム：</span><span class="text-info"
                                                                                v-html="selectedItem.group"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">Eメール：</span><span class="text-info"
                                                                                 v-html="selectedItem.email"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">端末数：</span><span class="text-info"
                                                                                v-html="selectedItem.devices?selectedItem.dev:0"></span>
                                <b-btn variant="outline-primary" size="sm" v-if="selectedItem.last_login"
                                       @click="showDevice">端末のリスト
                                </b-btn>
                            </div>
                        </div>
                    </div>
                    <b-button-group class="no-round" v-if="selectedItem">
                        <b-button variant="warning" @click="editUser">編集</b-button>
                        <b-button variant="danger" @click="deleteUser" v-if="!selectedItem.deleted">削除</b-button>
                        <b-button variant="secondary" v-if="selectedItem.is_active" @click="disable">無効化</b-button>
                        <b-button variant="primary" v-if="!selectedItem.is_active" @click="enable">有効化</b-button>
                    </b-button-group>
                </div>
            </div>
        </div>
        <b-modal id="modalUser" :title="form.title" size="md" ref="userModal" @ok.prevent="submitUser"
                 cancel-title="キャンセル" ok-title="保存">
            <b-form>
                <b-form-row>
                    <b-col>
                        <b-form-group
                            label="氏"
                            label-for="last_name">
                            <b-form-input id="last_name" v-model="form.last_name" placeholder="氏"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                            label="名"
                            label-for="first_name">
                            <b-form-input id="first_name" v-model="form.first_name" placeholder="名"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col>
                        <b-form-group
                            label="ユーザー名"
                            label-for="username">
                            <b-form-input id="username" v-model="form.username" placeholder="ユーザー名" type="email"
                                          required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                            label="Eメール"
                            label-for="email">
                            <b-form-input id="email" v-model="form.email" placeholder="Eメール"
                                          type="email"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-group
                    label="チーム"
                    label-for="team">
                    <b-form-select id="team" v-model="form.group_id" :options="teams" required></b-form-select>
                </b-form-group>
                <b-form-group
                    label="パスワード"
                    label-for="password"
                    :description="form.new ? '' : '変更がなければ空にしてください'">
                    <b-input-group>
                        <b-form-input id="password" v-model="form.password" :required="form.new" placeholder="パスワード"
                                      ref="password"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="outline-success" @click.prevent="copyPass"><i
                                class="icon ion-md-copy"></i></b-button>
                            <b-button variant="info" @click.prevent="createPass"><i class="icon ion-md-color-wand"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-checkbox
                    v-model="form.is_manager"
                    name="is_manager"
                    value="true"
                    unchecked-value="false">
                    マネージャー
                </b-form-checkbox>
            </b-form>
        </b-modal>
        <b-modal id="modalDeviceList" title="使用した端末" size="lg" ref="deviceModalList" @show="deviceModalOpen"
                 hide-footer>
            <div style="max-height: 600px; overflow-y: auto">
                <b-list-group>
                    <b-list-group-item v-for="device in devices" :key="device.device_id">
                        <i class="icon ion-md-tablet-portrait" v-if="device.md.tablet()"></i>
                        <i class="icon ion-md-phone-portrait" v-else-if="device.md.phone()"></i>
                        <i class="icon ion-md-laptop" v-else></i>
                        <span class="ml-2" v-bind:class="{ 'text-muted': !device.active }">{{ device.agent }}</span><br>
                        <small class="text-warning">{{ device.guid }}</small>&nbsp;
                        <small class="text-info">{{ device.last_ip }}</small>
                        <small class="text-secondary">{{ $moment(device.updated_at).calendar() }}</small>
                        <b-badge pill variant="info" class="float-right" v-if="device.md.os()">{{ device.md.os() }}</b-badge>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </b-modal>
    </div>
</template>

<script>
import MobileDetect from 'mobile-detect'

export default {
    name: 'Users',
    props: {
        teams: Array,
        teams2: Array
    },
    data() {
        return {
            form: {},
            devices: [],
            selectedItem: null,
            selectedTeam: null,
            totalRows: 0,
            currentPage: 1,
            perPage: 50,
            currentRows: '',
            fields: [
                {
                    key: 'id',
                    label: 'ユーザ－'
                },
                {
                    key: 'created',
                    label: '作成日付',
                    thClass: 'date'
                },
                {
                    key: 'group',
                    label: 'チーム',
                    thClass: 'group'
                }
            ]
        }
    },
    methods: {
        createPass() {
            let s = [...Array(10)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
            console.log(s)
            this.form.password = s;
            this.$refs.password.$el.value = s;
        },
        copyPass() {
            this.$refs.password.select();
            document.execCommand('copy');
        },
        addUser() {
            this.form = {
                new: true,
                title: 'ユーザー追加',
                id: 0
            };
            this.$refs.userModal.show();
        },
        submitUser() {
            if (this.form.new && !this.form.group_id) {
                return;
            }
            if (this.form.new && !this.form.password) {
                return;
            }
            this.$http.post('user/' + this.form.id, {
                first_name: this.form.first_name,
                last_name: this.form.last_name,
                username: this.form.username,
                email: this.form.email,
                group_id: this.form.group_id,
                is_manager: this.form.is_manager,
                password: this.form.password
            }).then((r) => {
                if (r) {
                    this.$refs.userModal.hide()
                    this.$refs.table.refresh()
                }
            }).catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        default:
                            if (error.response.data && error.response.data.error) {
                                this.$snotify.error(error.response.data.error);
                            } else {
                                this.$snotify.error('サーバーエラー');
                            }
                            break;
                    }
                }
            });
        },
        deviceModalOpen() {
            this.devices = [];
            if (this.selectedItem && this.selectedItem.devices) {
                this.selectedItem.devices.forEach(value => {
                    value.md = new MobileDetect(value.agent);
                    this.devices.push(value)
                })
            }
        },
        showDevice() {
            this.$refs.deviceModalList.show()
        },
        disable() {
            this.$swal({
                title: 'ユーザーを無効化',
                text: `「${this.selectedItem.first_name} ${this.selectedItem.last_name}」を無効化しますか？`,
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: 'キャンセル',
                confirmButtonText: '無効化'
            }).then((result) => {
                if (result.value) {
                    let $this = this;
                    this.$getComponent('App').loading(true)
                    this.$http.post('user/' + this.selectedItem.id + '?oper=disable').then((response) => {
                        this.$root.$emit('endSpinner');
                        if (response) {
                            this.$refs.table.refresh()
                        }
                    }).catch(function (error) {
                        $this.$root.$emit('endSpinner');
                        if (error.response) {
                            switch (error.response.status) {
                                case 404:
                                    $this.$snotify.error('指定したユーザーが存在しない');
                                    break;
                                case 403:
                                    $this.$snotify.error('操作の権限がありません');
                                    break;
                                default:
                                    if (error.response.data && error.response.data.error) {
                                        $this.$snotify.error(error.response.data.error);
                                    } else {
                                        $this.$snotify.error('サーバーエラー');
                                    }
                                    break;
                            }
                        }
                    })
                }
            })
        },
        enable() {
            this.$swal({
                title: 'ユーザーを有効化',
                text: `「${this.selectedItem.first_name} ${this.selectedItem.last_name}」を有効化しますか？`,
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: 'キャンセル',
                confirmButtonText: '有効化'
            }).then((result) => {
                if (result.value) {
                    let $this = this;
                    this.$getComponent('App').loading(true)
                    this.$http.post('user/' + this.selectedItem.id + '?oper=enable').then((response) => {
                        this.$root.$emit('endSpinner');
                        if (response) {
                            this.$refs.table.refresh()
                        }
                    }).catch(function (error) {
                        $this.$root.$emit('endSpinner');
                        if (error.response) {
                            switch (error.response.status) {
                                case 404:
                                    $this.$snotify.error('指定したユーザーが存在しない');
                                    break;
                                case 403:
                                    $this.$snotify.error('操作の権限がありません');
                                    break;
                                default:
                                    if (error.response.data && error.response.data.error) {
                                        $this.$snotify.error(error.response.data.error);
                                    } else {
                                        $this.$snotify.error('サーバーエラー');
                                    }
                                    break;
                            }
                        }
                    })
                }
            })
        },
        deleteUser() {
            // TODO
        },
        editUser() {
            this.form = this.selectedItem;
            this.form.title = 'ユーザー編集'
            this.$refs.userModal.show();
        },
        rowSelected(item) {
            if (item.length) {
                this.selectedItem = item[0];
                this.$http.get('user/' + this.selectedItem.id).then((r) => {
                    if (r) {
                        this.selectedItem = r.data.user;
                        let dev = 0
                        if (r.data.devices.length) {
                            let a = r.data.devices.filter(value => value.active).length
                            dev = r.data.devices.length + ' total (' + a + ' active)';
                        }
                        this.selectedItem.devices = r.data.devices;
                        this.selectedItem.dev = dev;
                    }
                }).catch((error) => {
                    if (error.response) {
                        switch (error.response.status) {
                            default:
                                if (error.response.data && error.response.data.error) {
                                    this.$snotify.error(error.response.data.error);
                                } else {
                                    this.$snotify.error('サーバーエラー');
                                }
                                break;
                        }
                    }
                });
            } else {
                this.selectedItem = null;
            }
        },
        teamChange() {
            this.currentPage = 1;
            this.$refs.table.refresh()
        },
        users(ctx, cb) {
            let params = '?page=' + ctx.currentPage + '&size=' + ctx.perPage + '&gid=' +
                (this.selectedTeam ? this.selectedTeam : '');
            ctx.busy = true;
            this.$http.get('user/list' + params).then((response) => {
                ctx.busy = false;
                this.totalRows = response.data.total;
                cb(response.data.users);
                let first = 0;
                if (response.data.users.length > 0) {
                    first = ((ctx.currentPage - 1) * ctx.perPage) + 1;
                    first = first + ' - ' + (first + response.data.users.length - 1)
                } else {
                    if (this.keyword || this.shot_date) {
                        this.$snotify.warning('結果がありません')
                    }
                }
                this.currentRows = first
            }).catch((error) => {
                ctx.busy = false;
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                }
                cb([])
                console.log(error)
            })
        }
    },
    mounted() {
        this.$root.$on('updateTable', () => {
            if (this.$refs.table) {
                this.$refs.table.refresh()
            }
        });
    }
}
</script>

<style scoped>
.no-round .btn {
    border-radius: 0;
}

#info-card {
    min-height: 300px;
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
}
</style>
