<template>
    <!-- Begin page content -->
    <main role="main" class="container-fluid">
        <b-alert variant="danger" v-if="news.system && news.system.length > 0" show dismissible
                 @dismissed="newsDismissed('system',news.system[0].aid)"><strong>{{ news.system[0].title }}</strong>
            <br>{{ news.system[0].body }}
        </b-alert>
        <b-alert variant="warning" v-if="news.group && news.group.length > 0" show dismissible
                 @dismissed="newsDismissed('group',news.group[0].aid)"><strong>{{ news.group[0].title }}</strong>
            <br>{{ news.group[0].body }}
        </b-alert>
        <div class="row">
            <div class="col">
                <form class="form-inline" @submit.prevent="search">
                    <div class="input-group mb-0">
                        <div class="input-group-prepend">
                            <b-form-select size="sm" v-model="selectedTeam" :options="teams"></b-form-select>
                            <b-input-group class="mb-0">
                                <b-form-input
                                    size="sm"
                                    id="example-input"
                                    v-model="shot_date"
                                    type="text"
                                    placeholder="撮影日"
                                    autocomplete="off"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker
                                        button-only
                                        right
                                        aria-controls="example-input"
                                        @context="onContext"
                                        size="sm"
                                        id="datepicker"
                                        v-model="shot_date"
                                        locale="ja"
                                        reset-button close-button
                                        calendar-width="260"
                                        :date-format-options="{ 'year': 'numeric', 'month': 'long', 'day': 'numeric', 'weekday': 'long' }"
                                    ></b-form-datepicker>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="input-group mb-0">
                            <!--
                            <datepicker v-model="shot_date" :disabledDates="$parent.datePicker.disabledDates" bootstrap-styling="" input-class="text-right"
                                        style="width: 150px" clear-button="" typeable="" placeholder="日付"
                                        :calendar-button-icon="$parent.datePicker.icon" :language="$parent.datePicker.language"
                                        :format="$parent.datePicker.format"></datepicker>
                                        -->
                        </div>
                        <input class="form-control form-control-sm" type="search" placeholder="キーワード" v-model="keyword"
                               aria-label="キーワード">
                        <div class="input-group-append">
                            <button class="btn btn-outline-success btn-sm" type="submit"><i
                                class="icon ion-md-search"></i> 検索
                            </button>
                        </div>
                    </div>
                </form>
                <b-table striped hover :items="videos" :fields="fields" :current-page="currentPage" primary-key="vid"
                         :per-page="perPage" bordered ref="table" @row-clicked="rowSelected" @refresh="tableRefresh"
                         small
                         show-empty empty-text="結果ありません">
                    <template v-slot:cell(name)="data">
                        <img :src="data.item.thumb?data.item.thumb:inProgress"
                             class="img-thumbnail thumb float-left mr-3">
                        <div>{{ data.value }}</div>
                        <small class="text-muted">{{ data.item.description }}</small><br>
                        <small class="text-info">{{ data.item.group }}</small><br>
                        <small class="text-warning">{{ data.item.shot_date }}</small><br>
                        <small class="text-secondary">{{ $moment(data.item.created).local().calendar() }}に追加</small>
                        <b-badge pill variant="secondary" class="float-right"
                                 v-if="$parent.user.is_manager && data.item.archived">アーカイブ
                        </b-badge>
                        <b-badge pill :variant="$parent.statusVariant[data.item.status]" class="float-right"
                                 v-if="$parent.user.is_manager && !data.item.archived">
                            {{ $parent.status[data.item.status] }}
                        </b-badge>
                        <b-badge pill variant="warning" class="float-right mr-1" v-if="data.item.shared">公開中</b-badge>
                        <b-badge pill variant="light" class="float-right mr-1" v-if="data.item.data">タグ付き</b-badge>
                    </template>
                </b-table>
                <b-row>
                    <b-col class="my-1">
                        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0"
                                      @change="changePage"/>
                    </b-col>
                    <b-col class="my-1">
                        <span>{{ currentRows }} / {{ totalRows }}</span>
                    </b-col>
                </b-row>
            </div>
        </div>

        <!-- video info modal -->
        <b-modal @shown="modalShown" ref="videoInfoModal" centered hide-footer size="lg"
                 :title="selectedItem ? selectedItem.name : ''" class="videoModal"
                 no-enforce-focus body-class="video-modal-body" header-class="video-modal-header">
            <div class="thumbLarge" v-if="selectedItem && selectedItem.status === 'ready'" @click.prevent="playVideo"
                 :style="{ cursor: 'pointer', 'backgroundImage': 'url(' + (selectedItem.poster?selectedItem.poster:inProgress) + ')' }">
                <img src="@/assets/img/play-button.png" alt="再生" class="playbutton" v-if="!selectedItem.archived">
            </div>
            <div class="thumbLarge" v-if="selectedItem && selectedItem.status !== 'ready'"
                 :style="{backgroundImage: 'url('+inProgress+')'}">
            </div>
            <div class="card-body" v-if="selectedItem">
                <div class="card-text mb-3 small">
                    <div>
                        <span class="font-weight-bold">詳細：</span><span class="text-muted"
                                                                       v-html="selectedItem.description"></span>
                    </div>
                    <div>
                        <span class="font-weight-bold">日付：</span><span class="text-muted"
                                                                       v-html="selectedItem.shot_date"></span>
                    </div>
                    <div>
                        <span class="font-weight-bold">チーム：</span><span class="text-muted"
                                                                        v-html="selectedItem.group"></span>
                    </div>
                    <div>
                        <span class="font-weight-bold">公開チーム数：</span><span class="text-muted"
                                                                           v-html="selectedItem.sharedCnt"></span>
                    </div>
                </div>
            </div>
            <b-button-group class="no-round w-100"
                            v-if="$parent.user.is_manager && selectedItem && selectedItem.group_id === $parent.user.group.group_id">
                <b-button variant="warning" @click="editVideo" title="編集"><i class="icon ion-md-create"></i></b-button>
                <b-button variant="danger" @click="deleteVideo" title="削除"><i class="icon ion-md-trash"></i></b-button>
                <b-button variant="secondary" v-if="selectedItem.status === 'ready' && !selectedItem.archived"
                          @click="archiveVideo" title="アーカイブ"><i class="icon ion-md-archive"></i></b-button>
                <b-button variant="primary" v-if="selectedItem.archived" @click="restore" title="復元"><i
                    class="icon ion-md-refresh"></i></b-button>
                <b-button variant="info" v-if="selectedItem.status === 'ready' && !selectedItem.archived"
                          @click="createOneTimeURL" title="DLリンク生成">
                    <i class="icon ion-md-code-download"></i>
                </b-button>
            </b-button-group>
            <b-button-group class="no-round w-100"
                            v-if="$parent.user.is_manager && selectedItem && selectedItem.group_id !== $parent.user.group.group_id && selectedItem.acl.find(acl=>acl.group_id_id === $parent.user.group.group_id && acl.download)">
                <b-button variant="info" @click="createOneTimeURL" title="DLリンク生成">
                    <i class="icon ion-md-code-download"></i>
                </b-button>
            </b-button-group>
            <b-button-group class="no-round w-100"
                            v-if="selectedItem && selectedItem.group_id === $parent.user.group.group_id">
                <b-button variant="success" @click="searchLabelVideo" title="タグ検索"
                          v-if="selectedItem.data">
                    <i class="icon ion-md-search"></i> タグ検索
                </b-button>
            </b-button-group>
        </b-modal>
    </main>
</template>

<script>
// import Datepicker from 'vuejs-datepicker';
import inProgress from '@/assets/img/inprogress.svg'

export default {
    name: 'Main',
    // components: {
    //     Datepicker
    // },
    props: {
        groups: Array,
        user: Object
    },
    data() {
        let fields = [
            {
                key: 'name',
                label: '映像'
            }
        ];

        return {
            inProgress,
            shot_date: '',
            keyword: '',
            news: {},
            totalRows: 0,
            currentPage: 1,
            perPage: 20,
            currentRows: '',
            fields: fields,
            selectedItem: null,
            sortBy: 'created',
            sortDesc: true,
            selectedTeam: null,
            formatted: '',
            selected: ''
        }
    },
    methods: {
        searchLabelVideo() {
            this.$root.$emit('openVideoSearchModal', this.selectedItem);
        },
        onContext(ctx) {
            // The date formatted in the locale, or the `label-no-date-selected` string
            this.formatted = ctx.selectedFormatted
            // The following will be an empty string until a valid date is entered
            this.selected = ctx.selectedYMD
        },
        tableRefresh() {
            this.selectedItem = null;
        },
        search() {
            this.selectedItem = null;
            this.$refs.table.refresh()
        },
        modalShown() {
            this.$root.$off('focusin.modal')
            // TODO: right now not using this yet, for future use
        },
        playVideo() {
            if (this.selectedItem.archived) {
                return;
            }
            this.$parent.getPlayURL(this.selectedItem, url => {
                if (typeof Android !== 'undefined' && /amazon/i.test(navigator.userAgent)) {
                    // eslint-disable-next-line no-undef
                    Android.openVideo(url.mpd, this.selectedItem.name)
                } else {
                    this.$root.$emit('openVideo', url, this.selectedItem);
                }
            })
        },
        rowSelected(item) {
            this.selectedItem = item;
            this.$refs.videoInfoModal.show()
            /* eslint-disable no-undef */
            this.$log( 'view_item', {'event_label': this.selectedItem.name});
        },
        videos(ctx, cb) {
            let params = '?page=' + ctx.currentPage + '&size=' + ctx.perPage + '&date=' +
                (this.shot_date ? this.$moment(this.shot_date).format('YYYY-MM-DD') : '') + '&keyword=' + this.keyword +
                '&sort=' + this.sortBy + '&desc=' + this.sortDesc + (this.selectedTeam ? '&gid=' + this.selectedTeam : '');
            ctx.busy = true;
            this.$http.get('video/' + params).then((response) => {
                ctx.busy = false;
                this.totalRows = response.data.total;
                let videos = response.data.videos.map(x => {
                    x.sharedCnt = x.acl.filter(x => x.streaming).length - 1
                    x.shared = !x.archived && x.group_id === this.$parent.user.group.group_id && x.sharedCnt > 0;
                    return x
                })
                cb(videos);
                let first = 0;
                if (videos.length > 0) {
                    first = ((ctx.currentPage - 1) * ctx.perPage) + 1;
                    first = first + ' - ' + (first + videos.length - 1)
                } else {
                    if (this.keyword || this.shot_date) {
                        this.$snotify.warning('結果がありません')
                    }
                    this.selectedItem = null;
                }
                this.currentRows = first
            }).catch((error) => {
                ctx.busy = false;
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                }
                if (this.$ab) {
                    this.$ab.notify(error);
                }
                cb([])
                console.log(error)
            })
        },
        newsDismissed(type, aid) {
            switch (type) {
                case 'system':
                    this.$ls.set('news-s', aid)
                    break;
                case 'group':
                    this.$ls.set('news-g', aid)
                    break;
            }
        },
        /**
         * Create one time url
         **/
        createOneTimeURL() {
            if (this.selectedItem) {
                this.$parent.createOneTimeURL(this.selectedItem)
            }
        },
        editVideo() {
            this.$root.$emit('openVideoEditModal', this.selectedItem.video_id);
        },
        /**
         * Delete selected video
         */
        deleteVideo() {
            if (this.selectedItem) {
                this.$parent.deleteVideo(this.selectedItem)
            }
        },
        archiveVideo() {
            if (this.selectedItem) {
                this.$getComponent('Home').archiveVideo(this.selectedItem);
            }
        },
        changePage() {
            window.scrollTo(0, 0)
        },
        restore() {
            if (this.selectedItem) {
                this.$getComponent('Home').restoreVideo(this.selectedItem);
            }
        }
    },
    computed: {
        teams() {
            let l = this.groups.filter(item => item.contract).map(item => {
                return {text: item.name, value: item.group_id}
            });
            l.unshift({
                text: '自分のチーム', value: this.user.group.group_id
            });
            l.unshift({
                text: 'すべて', value: null
            })
            return l;
        }
    },
    mounted() {
        this.$root.$on('updateNews', (news) => {
            this.news = news
        });
        this.$root.$on('updateTable', () => {
            if (this.$refs.table) {
                this.$refs.table.refresh()
            }
        });
        this.$root.$on('closeInfoModal', () => {
            if (this.$refs.videoInfoModal) {
                this.$refs.videoInfoModal.hide()
            }
        });
    }
}
</script>

<style scoped>
.no-round .btn {
    border-radius: 0 !important;
}

</style>

<style>
.video-modal-body {
    padding: 0 !important;
}

.video-modal-header {
    padding: 0.5rem !important;
}
</style>
