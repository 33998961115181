<template>
    <b-modal id="login" title="" size="sm" centered no-close-on-esc hide-header-close busy no-close-on-backdrop
             ref="loginModal">
        <div slot="modal-header" class="w-100 text-center">
            ログイン
        </div>
        <div class="logo">
            <img src="@/assets/img/eve_icon_265.png" alt="EVE">
        </div>
        <b-form @submit.prevent="onSubmit">
            <b-form-group label="ユーザー名"
                          label-for="login-username">
                <b-form-input id="login-username"
                              type="email"
                              ref="username"
                              autocorrect="off" autocapitalize="off"
                              spellcheck="false"
                              required
                              placeholder="ユーザー名"
                              @focus="focus"
                              @blur="blur"
                              style="ime-mode: disabled">
                </b-form-input>
            </b-form-group>
            <b-form-group label="パスワード"
                          label-for="login-password">
                <b-form-input id="login-password"
                              type="password"
                              ref="password"
                              required
                              placeholder="パスワード">
                </b-form-input>
            </b-form-group>
            <b-form-group class="mb-3">
                <b-form-checkbox id="login-save" v-model="save" value="1" unchecked-value="0">ログイン情報を保存
                </b-form-checkbox>
            </b-form-group>
            <b-button type="submit" variant="primary" block class="mb-3">
                ログイン
            </b-button>
        </b-form>
        <div slot="modal-footer" class="w-100 text-right">
            <router-link to="/terms" class="underline" target="_blank">サイトの規約</router-link>
        </div>
    </b-modal>
</template>

<script>
import mixpanel from 'mixpanel-browser'

export default {
    name: 'Login',
    data() {
        return {
            save: 0
        }
    },
    methods: {
        focus() {
            this.$refs.username.$el.type = 'email'
        },
        blur() {
            this.$refs.username.$el.type = 'text'
        },
        onSubmit() {
            this.$getComponent('App').loading(true)
            this.$http.post('login', {
                username: this.$refs.username.$el.value,
                password: this.$refs.password.$el.value,
                save: this.save,
                guid: this.$guid()
            }).then(response => {
                if (response) {
                    this.$parent.user = response.data.user;
                    this.$ls.set('user', response.data.user);
                    this.$parent.loggedIn = true;
                    if (this.save) {
                        this.$ls.set('password', response.data.password);
                        this.$ls.set('token', response.data.token);
                    } else {
                        this.$ls.set('token', response.data.token, 86400000);
                    }
                    this.$http.defaults.headers['Authorization'] = 'Token ' + response.data.token;
                    this.$refs.loginModal.hide();
                    /* eslint-disable no-undef */
                    gtag('set', {'user_id': response.data.user.username}); // Set the user ID using signed-in user_id.
                    mixpanel.identify(response.data.user.username)
                    mixpanel.register_once({
                        'Name': response.data.user.name,
                        'Team': response.data.user.group.name
                    });
                }
                this.$log( 'login', {'method': 'Normal'});
                if (typeof Android !== 'undefined') {
                    Android.reload();
                } else {
                    window.location.reload();
                }
            }).catch(error => {
                this.$root.$emit('endSpinner');
                if (error.response) {
                    switch (error.response.status) {
                        case 404:
                            this.$snotify.error('ユーザー名又はパスワードが間違っています');
                            break;
                        case 400:
                            this.$snotify.error('無効なデータ');
                            break;
                        default:
                            if (error.response.data && error.response.data.error) {
                                this.$snotify.error(error.response.data.error);
                            } else {
                                this.$snotify.error('サーバーエラー');
                            }
                            break;
                    }
                }
            })
        }
    },
    mounted() {
        if (this.$refs.loginModal) {
            this.$refs.loginModal.show();
        }
    }
}
</script>

<style scoped>
.underline {
    text-decoration: underline;
}

.logo {
    text-align: center;
    margin-bottom: 10px;
}

.logo img {
    height: 100px;
}
</style>
